import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import SvgIcons from "../components/svgIcons/svgIcons";
import { Link } from "gatsby";

const Price = () => {
  return (
    <Layout>
      <div className="container">
        <h1 className="mt-30 mb-30 text-center">Прайс лист</h1>
        <div className="price">
          <div className="price__body price__item">
            <p>
            Уважаемые клиенты, для заказа качественной трубопроводной арматуры DENDOR обращайтесь в отдел продаж
            </p>
            <br />
            <p>
              Наши менеджеры с радостью помогут вам выбрать нужную ТПА,
              проконсультируют по условиям и срокам поставки.
            </p>
            <br />
            <div className="price__List">
              <div className="price__icon">
                <SvgIcons
                  width="32"
                  height="32"
                  name="pdf2"
                  className="icon_svg price"
                />
              </div>
              <div className="price__link">
                <a href="http://static.dendor.ru/pdf/price.pdf">Прайс лист DENDOR от 01.04.2022</a>
              </div>
            </div>
            <br />
            <div className="price__List">
              <div className="price__icon">
                <SvgIcons
                  width="32"
                  height="32"
                  name="pdf2"
                  className="icon_svg price"
                />
              </div>
              <div className="price__link">
                <a href="http://static.dendor.ru/pdf/delivery_price.pdf">
                  Прайс-лист доставки по Санкт-Петербургу
                </a>
              </div>
            </div>
            <br />
            <p>
            Обращаем ваше внимание на то, что вся представленная на сайте информация носит исключительно информационный характер.
            </p>
            <br />
            <p>
            Характеристики и цены могут быть изменены без предварительного уведомления.
            </p>
            <br />
            <p>
            По всем имеющимся вопросам вы можете связаться с нами по телефону или отправить сообщение на e-mail, указанный в <Link to="/contacts">разделе контакты</Link>
            </p>
          </div>
          <div className="price__images">
            <StaticImage
              className="news__images"
              alt="Прайс лист на продукцию компании Dendor"
              src="../images/price.jpg"
              placeholder="#fff"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Price;
